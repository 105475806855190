/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/70599/static-files@master/js/common.min.js
 * - /npm/gsap@3.8.0/dist/gsap.min.js
 * - /npm/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js
 * - /npm/yall-js@3.2.0/dist/yall.min.js
 * - /npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
